import React, { useState } from "react"
import produce from "immer"

import Box from "./box"
import Checkbox from "./checkbox"
import Grid from "./grid"
import Heading from "./heading"
import Inline from "./inline"
import Stack from "./stack"
import Text from "./text"

const MoodBarometer = ({ contents, onUpdate, ...restProps }) => {
  const [checkedIds, setCheckedIds] = useState([])

  const onCheckboxClicked = (event) => {
    const isChecked = checkedIds.includes(event.target.id)
    if (event.target.checked === isChecked) {
      return
    }

    const newState = produce(checkedIds, (draft) => {
      if (event.target.checked) {
        draft.push(event.target.id)
      } else {
        const index = draft.findIndex((id) => id === event.target.id)
        if (index !== -1) {
          draft.splice(index, 1)
        }
      }
    })

    setCheckedIds(newState)
    onUpdate({ checkedIds: newState })
  }

  return (
    <Box {...restProps}>
      <Stack space={3}>
        <Heading>Stimmungsbarometer</Heading>
        <Grid columns={[1, 2]}>
          <Box>
            {contents.map((content) => (
              <Checkbox
                key={content.id}
                id={content.id}
                name={content.id}
                value={content.id}
                onChange={onCheckboxClicked}
              >
                {content.title}
              </Checkbox>
            ))}
          </Box>
          <Box
            sx={{
              bg: "background",
              borderWidth: 4,
              borderStyle: "dashed",
              borderColor: "border",
              borderRadius: "lg",
              p: 6,
            }}
          >
            {checkedIds.length > 0 ? (
              <Inline space={3}>
                {checkedIds.map((id) => (
                  <Text as="span" size={6} key={id}>
                    {contents.find((content) => content.id === id).mood}
                  </Text>
                ))}
              </Inline>
            ) : (
              <Text sans color="whisper">
                Du hast noch keine Stimmungen ausgewählt
              </Text>
            )}
          </Box>
        </Grid>
      </Stack>
    </Box>
  )
}

export default MoodBarometer
